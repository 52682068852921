.note-card-container {
	h4 {
		display: flex;
		gap: 16px;

		.note-create-button {
			@include themify($themes) {
				color: themed("topBackground");
			}
			display: flex;
			gap: 5px;
			cursor: pointer;
		}
		margin-bottom: 16px;
	}
}

.note-container {
	@include themify($themes) {
		border-left: 2px solid themed("topBackground");
	}
	// width: 50%;
	// min-height: 100px;
	// max-height: 500px;
	padding: 1px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 5px;
	margin-bottom: 16px;
}

.note-title {
	font-size: 12px;
	font-weight: 700;
}

.note-date-card {
	font-size: 10px;
	font-weight: 300;
	margin-left: 5px;
}

.note-header {
	margin-bottom: 20px;
	text-transform: capitalize;
}

.note-text-card {
	font-size: 12px;
	font-weight: 400;
	margin-left: 5px;
}

.note-text-area {
	width: 100%;
	min-height: 150px;
}

.note-text-area-card {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	gap: 10px;
}
