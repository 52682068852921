.form__form-group-icon {
	background: #f2f4f7;
	border: 1px solid #f2f4f7;
}

.form__form-group-field {
	input {
		border: 1px solid #f2f4f7;
		color: #646777;
	}
}

.form__form-group-button {
	border: none;
}

.form_footer {
	width: 100%;
	display: flex;
	align-items: center;
	border-top: 1px solid #d3d3d3;
	padding-top: 10px;
	margin-top: 20px;
	margin-bottom: -15px;
}

.container-error {
	padding: 10px 0 10px 5px;
	margin-bottom: 10px;
	border: 2px solid #eb591a;
	border-radius: 5px;
	position: relative;

	p {
		color: #eb591a;
		font-weight: bold;
	}

	span {
		position: absolute;
		top: 0;
		right: 0;

		color: #eb591a;
		font-weight: bold;
		padding: 10px;
		cursor: pointer;
		transition: all opacity 0.5;

		&:hover {
			opacity: 0.8;
		}
	}
}
