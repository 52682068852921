.container-status-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
	// height: 80px;

	padding-top: 10px;
	margin: 0 5px;

	background: #f2f2f2;
	border-radius: 10px;
	box-shadow: 0px 10px 10px rgba(49, 71, 90, 0.11);
}

.body-status-card {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title-status-card {
	font-weight: 700;
	font-size: 16px;
}

.count-status-card {
	font-weight: 700;
	font-size: 24px;
}
