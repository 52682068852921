.badge {
	@include themify($themes) {
		&.badge-primary {
			background-color: themed("topBackground");
			border-color: themed("topBackground");

			&:before {
				background-color: themed("topHover");
			}

			&:hover,
			&:focus,
			&:active,
			&:active:focus {
				border-color: themed("topHover");
			}
		}

		&.badge-danger {
			background-color: #d30000;
		}

		&.badge-warning {
			background-color: #bfb800;
			color: #fff;
		}

		&.hirerTable {
			margin-left: 35%;
			width: 70px;
		}
	}
}
